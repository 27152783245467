import styled from 'styled-components'
import { Button, Heading, Text } from '@pancakeswap/uikit'
// import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import Link from 'next/link'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
  background-image: url('/images/football/background/404_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const NotFound = ({ statusCode = 404 }: { statusCode?: number }) => {
  const { t } = useTranslation()

  return (
    <StyledNotFound>
      <Heading scale="xxl" color="text">
        {statusCode}
      </Heading>
      <Text mb="16px">{t('Oops, page not found.')}</Text>
      <Link href="/" passHref>
        <Button as="a" scale="sm">
          {t('Back Home')}
        </Button>
      </Link>
    </StyledNotFound>
  )
}

export default NotFound
